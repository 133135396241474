<template>
    <!-- PAGE HEADER -->
	<div class="page_header"></div>
	<!-- // PAGE HEADER -->

    <div class="container single-post padding-bottom-30">
		<div class="row">

			<div class="clearfix"></div>
			<div class="col-md-8 col-sm-7 padding-bottom-30">
                
				<div class="surface-section">
					<div class="font-medium text-3xl text-900 mb-3">{{ kegiatan.nama }}</div>
					<div class="text-500 mb-5" v-html="kegiatan.deskripsi"></div>
					<ul class="list-none p-0 m-0">
						<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
							<div class="text-500 w-6 md:w-2 font-medium">Tanggal</div>
							<div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">{{ $filter_date(kegiatan.tanggal) }}</div>
						</li>
						<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
							<div class="text-500 w-6 md:w-2 font-medium">Waktu</div>
							<div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">{{ kegiatan.waktu_mulai?.substr(0,5) }} - {{ kegiatan.waktu_selesai?.substr(0,5) }} WIB</div>
						</li>
						<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
							<div class="text-500 w-6 md:w-2 font-medium">Absensi</div>
							<div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
								<div v-if="$store.getters.isAuth">
									<div v-if="$is('Member') && $can('member-absensi-kegiatan')">
										<Button v-if="$is('Member') && $can('member-absensi-kegiatan')" @click="absensiKegiatan(kegiatan.id)" class="p-button-primary mr-2">Hadir</Button> 
										<small>* Lakukan pada saat kegiatan sedang berlangsung!</small>
									</div>
									<span v-else class="p-error">Hanya untuk member</span>
								</div>
                                <div v-else>
									Login Terlebih Dahulu <Button @click="toLogin(`/auth/login?redirect=/k/${kegiatan.id}/${kegiatan.slug}`)" class="p-button-primary">Login</Button>
								</div>
							</div>
						</li>
					</ul>
				</div>

			</div>
	
			<!-- SIDEBAR -->
			<Sidebar :sidebar="sidebar" />
			<!-- // SIDEBAR -->

		</div>
	</div>
	<!-- // CONTENT -->
</template>

<script>
import Sidebar from '@/landing/_Sidebar.vue'

export default {
    components: {
		Sidebar,
	},
	props: ['settings', 'sidebar', 'id', 'slug'],
	data() {
		return {
			api: '/api/landing',
			kegiatan: {},
			skeleton: {
				kegiatan: true
			}
		}
	},
	computed: {
		
 	},
	methods: {
		loadKegiatan(id, slug){
			this.$Progress.start();
			this.$httpAuth.get(`${this.api}/kegiatan/${id}/${slug}`)
			.then((response) => {
				this.$Progress.finish();
				this.skeleton.kegiatan = false;
				this.kegiatan = response.data;
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},
		toLogin(url){
			window.location = url
		},
		absensiKegiatan(kegiatan_id){
			this.$Progress.start();
			this.$httpAuth.post(`${this.api}/absensi-kegiatan`, { kegiatan_id })
			.then((response) => {
				this.$Progress.finish();
				this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		}
	},
	created(){
		this.loadKegiatan(this.id, this.slug)
	},
	mounted() {
		
	},
}
</script>

<style scoped>
.img-responsive {
    width: 100%;
}
</style>